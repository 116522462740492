/* ACCORDION
---------------------------------------------------------- */

.faq {
    list-style: none;
    margin: 0;
    padding: 0;

    .fancy-collapse {
        margin: 0;
        padding: 0;

        .collapse-controller {
            position: relative;
            display: block;
            margin: 10px 0 1px;
            padding: 10px 20px;
            color: @white;
            background-color: @brand-primary;
            transition: all @transition-default-time @transition-default-animation;
            outline: none;

            &:hover,
            &:active,
            &:focus,
            &:visited {
                text-decoration: none;
            }

            &:after,
            &.collapsed:after {
                position: absolute;
                top: 50%;
                right: 20px;
                transform: translateY(-50%);
                color: @text-color;
            }

            &:after {
                color: white;
                .fa-icon("\F077");
            }

            &.collapsed {
                color: @brand-primary;
                background-color: @white;

                &:hover {
                    color: @white;
                    background-color: @brand-primary;

                    &:after {
                        color: @white;
                    }
                }

                &:after {
                    .fa-icon("\F078");
                }
            }
        }

        .collapse-content  {
            margin-bottom: 1px;
            padding: 20px;
            background-color: @white;

            h3 {
              font-size: @font-size-base;
              text-decoration: none;
            }
        }
    }
}

/* CATEGORIES
---------------------------------------------------------- */

.categories {
    margin-top: 30px;
}

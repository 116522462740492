/*  SLIDER
---------------------------------------------------------- */

.homepage-slider {
    position: absolute;
    top: 51px; // height of .user-navigation
    left: 0;
    right: 0;

    .container {
        width: 100%;
    }

    .carousel {
        &-inner {
            .img-responsive {
                height: 494px; // height of header minus height of .user-navigation
                margin: 0 auto;
            }
        }

        &-control-bar {
            display: none;
        }
    }
}

/*  ICON NAVIGATION
---------------------------------------------------------- */

.home-page .icon-navigation {

    display: flex;
    justify-content: center;
    align-items: center;

    &-wrapper {
        margin-top: -10px;
        background-color: @white;
        border-bottom: 0;
        //border-bottom: @border-default-width @border-default-style @border-default-color;
    }

    &-item {

        display: flex;
        flex-direction: column;
        color: @brand-primary;

        padding: 15px 2rem 15px 2rem;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        text-decoration: none;
        transition: color @transition-default-time @transition-default-animation;

        i {
            font-size: 20px;
        }

        &.active,
        &:hover {
            color: @link-hover-color-dark;
            text-decoration: none;

            .icn {
                stroke: @link-color;
            }
        }

        .icn {
            display: block;
            width: 100%;
            margin-bottom: 5px;
            transition: stroke @transition-default-time @transition-default-animation;
        }
    }

    // slick carousel styles
    &.slick-slider {
        width: 100%;
    }

    .slick {
        &-list {
            margin: 0 40px;

            @media (min-width: @screen-lg-min) {
                margin: 0;
            }
        }

        &-slide {
            @media (min-width: @screen-lg-min) {
                &:not(:last-child) {
                    border-right: @border-default-width @border-default-style @border-default-color;
                }
            }
        }

        &-arrow {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
            cursor: pointer;
            font-size: 2rem;
        }

        &-prev {
            left: 0;
        }

        &-next {
            right: 0;
        }
    }

}

/*  MAIN CONTENT
---------------------------------------------------------- */

.startsite_content {
    > div {
        padding-bottom: 40px;
    }
}


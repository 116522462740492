.order-success {
    h1 {
        margin: 44px 0 22px;
    }

    h1, h2 {
        //color: @brand-primary;
    }

    .btn {
        display: block;
        margin-top: 10px;
        margin-bottom: 10px;

        @media (min-width: @screen-sm-min) {
            display: inline-block;
        }
    }

    .step {
        margin-top: 40px;
        margin-bottom: 60px;
        font-size: @font-size-large;
        text-align: center;

        .icon {
            position: relative;
            display: inline-block;
            width: 100px;
            height: 100px;
            margin-bottom: 10px;
            color: @brand-primary-dark;
            font-size: (@font-size-large * 2);
            border: @border-default-width @border-default-style @border-default-color;
            border-radius: 50%;

            span {
                position: absolute;
                top: 3px;
                right: 3px;
                display: block;
                height: 26px;
                width: 26px;
                line-height: 26px;
                font-weight: bold;
                font-size: @font-size-small;
                color: white;
                background: @brand-primary;
                border-radius: 50%;
            }

            i {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}

.carousel-control-bar {
    position: relative;
    width: inherit;
}

.carousel-controls {
    padding: 1px @padding-base-vertical;

    div.col-sm-1, div.col-sm-11 {
        padding: @padding-base-horizontal/4 @padding-base-vertical;
    }

    #slider-toggle {
        margin: auto auto;
        display: block;
        width: 25px;
        height: 25px;
        font-size: 20px;
    }

    #slider-toggle:hover {
        text-decoration: none !important;
    }

    .carousel-navigation {

    }

    .carousel-indicators {
        position: relative;
        display: inline-block;
        bottom: 0;
        margin: 0;
        top: 0;
        left: 0;
        z-index: 0;
    }

    ol.carousel-indicators {
        height: inherit;
        padding: @padding-base-horizontal/2 0;
        max-width: 50%;
        li {
            background-color: white;
            width: 15px;
            height: 15px;
            border-radius: 50px;
            border: 1px solid @brand-primary;
            box-sizing:border-box;
            margin: 0;
        }
        li.active {
            background-color: @brand-primary;
        }
    }

    .carousel-control {
        display: inline-block;
        position: relative !important;
        background-image: none !important;
        background-color: @brand-primary !important;
        width: auto;
        opacity: 0.7;
        span {
            line-height: 0.85;
            position: relative !important;
            margin: auto auto;
            top: 0;
            right: 0;
            left: 0;
        }
    }
    .carousel-control.left {
        float: left;
    }

    .carousel-control.right {
        float: right;
    }
}
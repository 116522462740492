.btn {
  font-size: 14px;
  font-weight: bold;
  padding: 14px;
  transition: background-color @transition-default-time @transition-default-animation;
  white-space: pre-wrap;

  &-danger {
    .button-variant(@btn-danger-color, @btn-danger-bg, @btn-danger-border);
  }

  &-dark {
    .button-variant(@btn-dark-color, @btn-dark-bg, @btn-dark-border);
  }

  &-muted {
    .button-variant(@btn-muted-color, @btn-muted-bg, @btn-muted-border);
  }

  @media (min-width: @screen-md-min) {
    &-block {
      &:after {
        position: relative;
        top: 1px;
        margin-left: 10px;
        opacity: .7;
        .fa-icon("\F054");
        transition: margin @transition-default-time @transition-default-animation;
      }

      &:hover {
        &:after {
          margin-left: 20px;
        }
      }
    }
  }
}

/*  DEVICES
---------------------------------------------------------- */

.devices {
    .device {
        margin-bottom: 20px;
        color: @brand-primary-dark;
        background-color: white;

        .device-detail-hover {
            position: relative;
            display: block;
            overflow: hidden;
            text-align: center;
            cursor: pointer;
            background-color: @gray-lighter;

            @media (min-width: @screen-md-min) {
                > a > img {
                    opacity: 1;
                    transition: all @transition-default-time @transition-default-animation;
                }

                &:before {
                    position: absolute;
                    top: -99999px;
                    left: -99999px;
                    z-index: 1;
                    font-size: 118px;
                    .fa-icon("\F05A");
                    color: @brand-primary-light;
                    transition: top (@transition-default-time * 2) @transition-default-animation;
                }

                &:hover {
                    > a > img {
                        opacity: .5;
                        transform: scale(1.1);
                    }

                    &:before {
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
            }
        }

        .title {
            padding: 20px;
            font-size: @font-size-h3;
            font-weight: bold;

            > strong {
                display: block;
            }

            a {
                color: @brand-primary-dark;
                text-decoration: none;
            }

            small {
                display: block;
                font-size: 65%;
                font-weight: normal;
            }

            .quantities {

                &.empty {
                    background-color: transparent;
                    visibility: hidden;
                    opacity: 0;
                }

                > div {
                    font-size: 14px;
                    font-weight: normal;
                    margin: 10px auto;
                    background-color: #337ab7;
                    color: #fff;
                    padding: 6px 10px;
                    text-align: center;
                    display: inline-block;
                    vertical-align: middle;
                    border-radius: 10px;

                    > span {
                        font-size: 18px;
                        font-weight: bold;
                        display: inline-block;

                    }
                }

            }
        }

        .device-body {
            margin-bottom: 2.5rem;
        }

        .subtitle {
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 10px;
            font-weight: 400;
        }

        .badge.topseller {
            .topseller(@topseller-badge-color, @white, 10px, 5px);
        }

        .price {
            padding: 20px;
        }

        .button-wrapper {
            border-top: @border-default-width @border-default-style @body-bg;
            padding: 20px;
        }
    }
}

/*  REGION TEASERS
---------------------------------------------------------- */

.region-teasers {
    padding: 25px 10px 0;
    background-color: @white;

    a {
        &:hover {
            text-decoration: none;
        }
    }

    .icn-bubble {
        @media (min-width: @screen-sm-min) {
            float: left;
            margin-right: 10px;
        }
    }

    .lead {
        font-size: @font-size-base;
    }
}

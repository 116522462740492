.tariff-overview {
    .slick {
        &-arrow {
            color: @brand-primary;
        }

        &-dots li button {
            border-radius: 0;
        }
    }
}

/*  BRAND GRADIENT BACKGROUND
---------------------------------------------------------- */

.brand-gradient-background() {
    background-image: linear-gradient(to bottom,#131d40, #142752 20%, #103668 40%, #0a467f 60%, #065a95 80%, #0871ac);
}

/*  FONTAWESOME ICON
---------------------------------------------------------- */

.fa-icon(@icon) {
    font-family: @font-awesome;
    font-weight: 900;
    content: @icon;
}

/* TOPSELLER
---------------------------------------------------------- */

.topseller(@background-color, @color, @postion-top, @position-left) {
    position: absolute;
    top: @postion-top;
    left: @position-left;
    padding: 8px 14px;
    color: @color;
    background-color: @background-color;
    font-size: 14px;
    font-weight: normal;
    border-radius: 0;

    &:before {
        .fa-icon("\F091");
        margin-right: 10px;
    }

    &:after {
        position: absolute;
        top: -5px;
        left: 0;
        width: 0;
        height: 0;
        content: "";
        border-top: 5px solid transparent;
        border-bottom: 0 solid transparent;
        border-right: 10px solid darken(@background-color, 20%);
    }
}

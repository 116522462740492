@font-face {
  font-family: 'icons-o2';
  src: url('@{font-dir}/icons-o2.eot?85685828');
  src: url('@{font-dir}/icons-o2.eot?85685828#iefix') format('embedded-opentype'),
  url('@{font-dir}/icons-o2.woff2?85685828') format('woff2'),
  url('@{font-dir}/icons-o2.woff?85685828') format('woff'),
  url('@{font-dir}/icons-o2.ttf?85685828') format('truetype'),
  url('@{font-dir}/icons-o2.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icons {
  &-o2 {
    &:before {
      font-family: "icons-o2";
      font-style: normal;
      font-weight: normal;
      speak: none;
      display: inline-block;
      text-decoration: inherit;
      width: 1em;
      margin-right: .2em;
      text-align: center;
      font-variant: normal;
      text-transform: none;
      line-height: 1em;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    &-lg {
      &:before {
        font-size: 3rem;
        width: 3rem;
      }
    }

    &-smartphone:before {
      content: '\e800';
    }

    &-goblet:before {
      content: '\e801';
    }

    &-rectangle:before {
      content: '\e802';
    }

    &-contract:before {
      content: '\e803';
    }

    &-wow:before {
      content: '\e804';
      width: 3.5rem
    }

    &-free-l:before {
      content: '\e805';
      width: 4.5rem
    }

    &-free-m:before {
      content: '\e806';
      width: 4.5rem
    }

    &-free-s:before {
      content: '\e807';
      width: 4.5rem
    }

    &-free-xl:before {
      content: '\e808';
      width: 5.0rem
    }
  }
}

/*  SLIDER
---------------------------------------------------------- */

// extra large
@media (max-width: 1140px) {

    .page-footer {
        .col-md-4 {
            padding-bottom: 2rem;
        }
    }

}

// large
@media (max-width: 960px) {
    .well {
        padding: 0;
    }
}

// medium
@media (max-width: 768px) {

    .btn-all-tariffs {
        margin-top: inherit !important;
        display: block;
        margin-bottom: 2rem;
    }

    .home-page {
        header {
            height: 158px;
        }
    }

    .navbar {
        &.navbar-default {

            padding: 0;
            margin-bottom: 29px;


            .navbar-header {
                button {
                    &.navbar-toggle {

                        border: none;
                        margin-right: 0;
                        padding-top: 1.5rem;

                        span {
                            &.icon-bar {
                                background-color: #fff;
                            }
                        }
                    }
                }
            }

            .navbar-collapse {
                background-color: rgb(8, 113, 172);
                margin: 29px -15px 0 -15px !important;
            }
        }
    }

    #tabswrapper {
        padding: 0;
    }

    .page-footer {
        ul {
            &.list-inline.footer-nav {
                li {
                    padding-left: 20px;
                    padding-right: 20px;
                    display: block;
                    padding-top: .5rem;
                    padding-bottom: .5rem;
                }
            }
        }
    }
    padding-left: 20px;
    padding-right: 20px;
    display: block;
    padding-top: .5rem;
    padding-bottom: .5rem;

}

// custom
@media (max-width: 600px) {
    .slick-track, .tariff-slide {
        width: auto;
        display: block;
        justify-content: center;
    }
}
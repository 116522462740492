.warenkorb {
    .table {
        tbody {
            tr {
                th{
                    border-top:none;
                }
                th,
                td {
                    padding: 10px 20px;
                }
            }
        }

        &-cart {
            .table {
                > tbody {
                    > th,td{
                        padding: 10px 40px;
                    }
                    > tr {
                        &:first-child {
                            > td {
                                border-top: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

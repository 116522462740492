.tarif-preview {
    .btn-block:after {
        display: none;
    }

    .square-thumb {
        position: relative;
        display: block;
        height: 60px;
        margin-bottom: 15px;
        text-align: center;
        border-radius: 0;

        i,
        span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: block;
            width: 100%;
        }
    }

    .tarif-thumb {
        border: @border-default-width @border-default-style @brand-primary;
    }

    .price {
        font-weight: bold;
        font-size: (@font-size-large * 1.2);
        margin: 0 10px;
    }
}

/*  TARIFF TABLE
---------------------------------------------------------- */

.tariffOverview {

    th, td {
        border-top: none !important;
    }

    thead {
        tr {
            color: white;
            .brand-gradient-background();

            th {
                padding: 20px 0;
                border: none !important;
            }

            td {
                border-top: none;
            }
        }
    }

    tbody {
        tr {

            &.bg-none {
                background-color: transparent !important;
            }

            &.bg-grey {
                background-color: @gray-light;
            }

            &.bg-primary {
                border-top: 5px @border-default-style @body-bg;
                border-bottom: 5px @border-default-style @body-bg;
            }

            &.bg-primary-light {
                color: #fff;
                background-color: @brand-primary-light !important;
            }

            &.bg-primary-dark {
                color: #fff;
                background-color: @brand-primary-dark !important;
            }

            &:nth-child(even) {
                background-color: @light-blue-even-child;
                &.buttons {
                    background-color: #fff;
                }

            }

            td {
                padding: 2rem 1rem 2rem 1rem;

                span {
                    &.is--small {
                        font-size: 16px;
                    }

                    &.is--big {
                        color: @brand-primary;
                        font-weight: bold;
                    }
                }
            }

            th {
                vertical-align: middle;
                padding-left: 1rem;
            }

            td, th {
                border-top: none;
            }
        }
    }
}

small.strikethrough-price,
span.strikethrough-price {
    margin-right: 20px;
}

.prices {
    padding-bottom: 4rem;
    .price {

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        min-height: 30px;
        font-size: 16px;
        color: #082745;

        .price-text, .price-value {
            font-weight: bold;
        }
    }
}

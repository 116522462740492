
//CK Styles
.ck-icon {
    &:before {
        font-family: @font-awesome;
        font-weight: 900;
        display: inline-block;
        width: 20px;
    }
    &.ck-phone {
        &:before {
            content: "\f095";
        }
    }
    &.ck-fax {
        &:before {
            content: "\f1ac";
        }
    }
    &.ck-email {
        &:before {
            content: "\f0e0";
        }
    }
}

.border {
    border: 1px solid #e9e9e9  !important;

    &-top     { border-top: 1px solid #e9e9e9 !important;    }
    &-bottom  { border-bottom: 1px solid #e9e9e9 !important; }
    &-left    { border-left: 1px solid #e9e9e9 !important;   }
    &-right   { border-right: 1px solid #e9e9e9 !important;  }

    @media (min-width: @screen-sm-min) {
        &-sm {
            border: 1px solid #e9e9e9  !important;

            &-top     { border-top: 1px solid #e9e9e9 !important;    }
            &-bottom  { border-bottom: 1px solid #e9e9e9 !important; }
            &-left    { border-left: 1px solid #e9e9e9 !important;   }
            &-right   { border-right: 1px solid #e9e9e9 !important;  }
        }
    }

    @media (min-width: @screen-md-min) {
        &-md {
            border: 1px solid #e9e9e9  !important;

            &-top     { border-top: 1px solid #e9e9e9 !important;    }
            &-bottom  { border-bottom: 1px solid #e9e9e9 !important; }
            &-left    { border-left: 1px solid #e9e9e9 !important;   }
            &-right   { border-right: 1px solid #e9e9e9 !important;  }
        }
    }

    @media (min-width: @screen-lg-min) {
        &-lg {
            border: 1px solid #e9e9e9  !important;

            &-top     { border-top: 1px solid #e9e9e9 !important;    }
            &-bottom  { border-bottom: 1px solid #e9e9e9 !important; }
            &-left    { border-left: 1px solid #e9e9e9 !important;   }
            &-right   { border-right: 1px solid #e9e9e9 !important;  }
        }
    }
}

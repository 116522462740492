#accordion-order {
    margin-top: 40px;

    .panel {
        &-body {
            padding: 20px;

            legend {
                margin-bottom: 10px;
                font-size: @font-size-h3;
                font-weight: bold;
                border-bottom: none;
            }

            .panel {
                &-heading {
                    margin-top: 10px;
                    padding: 10px 0;
                    /*border-color: @border-default-color;*/
                }

                &-body {
                    padding: 20px 0;
                }
            }

            .well {
                padding: 0;
            }
        }

        &-footer {
            padding: 20px;
            background-color: @white;
            border-top: @border-default-width @border-default-style @border-default-color;
        }
    }
}

.panel {
    .panel-heading .accordion-toggle:before {
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        content: "\f13a";
        padding-right: 5px;
    }

    .panel-heading .accordion-toggle.collapsed:before {
        content: "\f138 ";
    }
}

.equal, .equal > div[class*='col-'] {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    float: none;
    clear:both;

    .panel {
        display: flex;
        flex-direction: column;
        align-content: stretch;

        .panel-body.flex-grow {
            flex-grow: 1;
        }
    }
}

.panel-dark {
    .panel-variant(@btn-dark-border; @btn-dark-color; @btn-dark-bg; @btn-dark-border);
}

.flex-panel {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;

    -ms-flex-direction: column;
    flex-direction: column;

    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;

    min-height: 100%;

    .panel {
        &-heading,
        &-body,
        &-footer {
            -ms-flex-negative: 0;
            flex-shrink: 0;
        }

        &-heading {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;

            min-height: 115px;
        }

        &-title {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;

            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;

            -ms-flex-direction: column;
            flex-direction: column;

            -webkit-box-pack: end;
            -ms-flex-pack: end;
            justify-content: flex-end;

            width: 100%;
        }

        &-body {
            -webkit-box-flex: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
        }
    }
}

/*  TARIFF PANEL
---------------------------------------------------------- */

.tariff-wrapper {
    .tariff {
        position: relative;
        border-radius: 0;
    }

    .panel {
        &-heading {
            border-bottom: none;

            .tariff-name {
                > a {
                    cursor: help;
                }
            }
        }

        &-footer {
            background-color: transparent;
        }
    }
}
/*  USER NAVIGATION
---------------------------------------------------------- */
.navbar-default .navbar-nav > li > a{
    color: @white !important;
}

.user-navigation {
    font-size: 13px !important;
    background: #222324;

    li.cart .cart[data-count]:after{
        position: absolute;
        right: 11%;
        top: -20%;
        content: attr(data-count);
        font-size: 10px;
        line-height: 1em;
        padding: 0.3rem;
        text-align: center;
        font-weight: bold;
        color: #fff;
        border-radius: 100%;
        min-width: 15px;
        background-color: #337ab7;
    }

    #user-nav {
        display: table;
        vertical-align: middle;
        > ul {
            margin-top: 6px;

            display: table-cell;
            vertical-align: middle;

            > li {
                vertical-align: middle;
                height: inherit;
                > a {
                    vertical-align: middle;
                    height: inherit;
                    color: #d2d7d9;
                    line-height: 20px;
                    &:hover {
                        color: #d2d7d9;
                        background: transparent !important;
                        text-decoration: underline;
                    }
                }

                &.open {
                    > a {
                        background: transparent !important;
                        color: #d2d7d9;
                    }
                }
            }
        }
    }

    .navbar {

        &-brand {
            color: #d2d7d9;
            font-size: inherit;

            > img {
                max-height: 22px;
            }
        }

        &-toggle {
            padding: 9px 10px;
            color: @navbar-inverse-link-color;
        }

        &-nav {
            margin-top: 0;
        }

        &-header {
           // display: none !important;
        }

    }

    .vendor-logo {
        margin-top: -49px;
    }
}

/*  MAIN NAVIGATION
---------------------------------------------------------- */


.navbar-row {

    .dropup, .dropdown{
        position: inherit !important;
    }

    .nav > li{
        position: inherit !important;
    }

    .logo-o2 {
        position: relative;
        z-index: 2;
        display: inline-block;
        float: left;
        margin-top: 26px;
    }

    .navbar {
        z-index: 2;
        background: transparent;
        border: none;

        &-header {
            margin-top: 20px;
            margin-bottom: 20px;
        }

        &-toggle {
            border-color: @navbar-default-border;

            &:focus,
            &:hover {
                background-color: transparent;
            }

            .icon-bar {
                width: 28px;
                height: 4px;
                background-color: @navbar-default-color;
            }
        }

        &-nav {
            font-size: 18px;

            @media (min-width: @screen-sm-min) {
                margin-top: 30px;
                margin-left: 20px;
            }

            .open > a {
                background-color: transparent !important;
            }

            > li {
                > a {
                    position: relative;
                    color: @white !important;
                    background-color: transparent !important;
                    &:after {
                        content: "";
                        position: absolute;
                        left: 10px;
                        right: 10px;
                        bottom: -3px;
                        height: 1px;
                        background-color: @white;
                        opacity: 0;
                        transition: all .2s ease;
                    }

                    &:hover {
                        background-color: transparent;
                        &:after {
                            bottom: 0;
                            opacity: 1;
                        }
                    }
                }

                &.active {
                    a {
                        color: @white;
                        background-color: transparent !important;
                        &:hover {
                            color: @white !important;
                        }

                        &:after {
                            content: "";
                            position: absolute;
                            left: 10px;
                            right: 10px;
                            bottom: 0;
                            height: 1px;
                            background-color: @white;
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    .dropdown-menu {
        width: 100%;
        height: 405px;
        overflow: hidden;
        padding: 10px;
        margin: 0;
        color: white;
        background-color: transparent;
        box-shadow: none;
        border: none !important;

        a {
            &:after {
                display: none;
            }
        }

        .rabe-dropdown {
            padding: 10px 15px;

            a {
                color: white !important;
            }
        }
    }
}

/*  MENU DEVICES
---------------------------------------------------------- */

.menu-devices {
    &-heading {
        display: none;
    }

    &-body {
        margin: 34px 0 15px;
        text-align: center;

        @media (min-width: @screen-md-min) {
            margin-right: -15px;
            margin-left: -30px;
            text-align: left;
        }

        .img-responsive {
            display: inline-block;

            @media (min-width: @screen-md-min) {
                display: block;
            }
        }

        @media (min-width: @screen-md-min) {
            .col-md-6 {
                position: relative;

                &:nth-child(odd) {
                    &:before {
                        content: "";
                        position: absolute;
                        right: 0;
                        top: 20px;
                        bottom: -3px;
                        width: 1px;
                        background-color: @brand-primary;
                        opacity: .5;
                    }
                }

                &:after {
                    content: "";
                    position: absolute;
                    left: 15px;
                    right: 15px;
                    bottom: -11px;
                    height: 1px;
                    background-color: @brand-primary;
                    opacity: .5;
                }

                &:nth-child(n+3) {
                    &:after {
                        display: none;
                    }
                }
            }
        }

        a {
            position: relative;
            display: block;

            > span {
                display: block;
                margin-top: 26px;
            }
        }

        .device {
            &-name {
                display: block;
                font-size: @font-size-large;
                margin: 10px 0;
            }

            &-price {
                &-regular,
                &-new {
                    display: block;
                }

                &-new {
                    color: @brand-primary;
                }
            }
        }
    }
}

/*  MENU MANUFACTURER
---------------------------------------------------------- */

.menu-manufacturer {
    display: none;

    @media (min-width: @screen-lg-min) {
        display: block;
    }

    &-heading {
        margin-right: 1px; // minus margin of body items
        margin-left: 0;

        .col-md-6:first-child {
            display: none;
        }

        .col-md-6:nth-child(2) {
            width: 100%;

            .btn {
                display: block;
                background-color: fade(@brand-primary, 40%);
                border-color: transparent;
                border-radius: 0;
                transition: background-color .2s ease-in-out;

                &:hover {
                    background-color: fade(@brand-primary, 60%);
                }
            }
        }
    }

    &-body {
        margin-right: 0;
        margin-left: 0;

        .manufacturer {
            &-link {
                position: relative;
                display: block;
                height: 166px;
                margin-top: 1px;
                margin-right: 1px;
                background-color: fade(@brand-primary, 40%);
                transition: background-color .2s ease-in-out;

                &:hover {
                    background-color: fade(@brand-primary, 60%);
                }
            }

            &-img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}

/*  MENU TARIFFS
---------------------------------------------------------- */

.menu-tariffs {
    .menu-tariffs-heading {

        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 50px;

        &.is--hidden {
            visibility: hidden;
        }

        span {
            padding: 0 .5rem .5rem .5rem;
            border-bottom: 1px solid #fff;
        }
    }

    .menu-tariffs-body {

        a {
            display: block;
        }

        .box-container {
            display: flex;
            background-color: fade(@brand-primary, 40%);
            padding: 1rem;
            align-items: center;
            margin-bottom: 1rem;

            .box-icon {
                font-size: 40px;
                display: flex;
                justify-content: center;
                align-items: center;

                &.is--tariff {
                    background-color: transparent;
                    background-image: linear-gradient(to bottom, #131d40, #142752 20%, #103668 40%, #0a467f 60%, #065a95 80%, #0871ac);
                    min-width: 70px;
                    font-weight: bold;
                    margin-right: 1.5rem;
                }
            }

            .box-text {
                span {

                    &.headline {
                        font-weight: bold;
                        font-size: 16px;
                        display: block;
                    }

                    &.headline {
                        display: block;
                        font-weight: bold;
                    }
                }
            }

        }
    }

}

/*  MENU BOX
---------------------------------------------------------- */

.menu-box-wrapper {
    height: 390px;
    margin-right: -15px;
    margin-left: -14px;
}

.menu-box {
    display: flex;
    align-items: center;
    margin-top: 1px;
    background-color: fade(@brand-primary, 40%);
    transition: background-color .2s ease-in-out;

    &:hover {
        text-decoration: none;
        background-color: fade(@brand-primary, 60%);
    }

    &-height {
        &-1-1 {
            height: 392px;
        }

        &-1-2 {
            height: 193px;
        }

        &-1-3 {
            height: 129px;
        }

        &-1-4 {
            height: 96px;
        }

        &-1-5 {
            height: 77px;
        }
    }

    &-icon {
        display: none;

        @media (min-width: @screen-lg-min) {
            display: block;
            width: 113px;
            text-align: center;
        }

        .icn {
            stroke: white;
        }
    }

    &-text {
        flex-grow: 2;
        text-align: center;

        @media (min-width: @screen-lg-min) {
            text-align: left;
        }

        > * {
            margin: 0;
            padding: 0;
        }
    }
}

/*  NAV PILLS
---------------------------------------------------------- */

.nav-pills {
    > li {
        > a {
            border-radius: 0;
            transition: background-color @transition-default-time @transition-default-animation;
        }

        &.active {
            > a {
                color: @white;
                background-color: @nav-pills-active-link-hover-bg;
            }
        }
    }
}

div.no-padding{
    padding: 0 !important;
}

.list {
    &-no-listsquare {
        list-style: none;
        padding: 0 0 10px 30px;
    }

    &-checkmark {
        list-style: none;
        padding: 0;

        li {
            position: relative;
            padding-left: 3rem;
            margin-bottom: 1rem;

            &:before {
                position: absolute;
                top: 0;
                left: 0;
                display: inline-block;
                .fa-icon("\F00C");
            }
        }
    }
}

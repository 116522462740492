/*  DEVICE COLORS / DEVICE STORAGE
---------------------------------------------------------- */

.device {
    &-colors,
    &-storage {
        list-style: none;
        margin: 0;
        padding: 0;

        &-item {

            display: inline-block;
            width: 30px;
            height: 30px;

            &:not(:last-child) {
                margin-right: 5px;
            }

            a {
                display: block;
                width: 100%;
                height: 100%;
                font-size: @font-size-small;

                /*&.active {
                    border: 2px @border-default-style @brand-primary;
                }*/
            }
        }
    }

    &-colors {
        &-item {
            a {
                span {
                    display: none;
                }
            }
        }
    }

    &-storage {
        &-item {
            /*a {
                color: #fff;
                text-align: center;
                line-height: 50px;
            }*/
            a {
                padding-top: 1px;
                font-size: 12px;
                color: #fff;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                /*font-size: 20px;*/
                font-weight: bold;

                background-color: darkgray;
                border: 2px solid darkgray;

                &:hover {
                    color: #fff !important;
                    background-color: gray;
                    border: 2px solid gray;
                    text-decoration: none;
                }

                &.active {
                    background-color: @brand-primary-light;
                    border: 2px @border-default-style @brand-primary;

                    &:hover {
                        background-color: @brand-primary-dark;
                        border: 2px solid @brand-primary-dark;
                        color: #fff !important;
                        text-decoration: none;
                    }
                }

                span {
                    display: block;
                    line-height: normal;

                    &.is--first {
                        margin-bottom: -3px;
                    }
                }
            }
        }
    }
}
.device-colors-item > a.active {
    outline: 2px @border-default-style @brand-primary;
}

/*  FORM
---------------------------------------------------------- */

.form {
    &-control {
        border-color: @input-border-color;
        border-radius: @input-border-radius;

        &:focus {
            box-shadow: none;
        }
    }
}

/* CHECKBOX
---------------------------------------------------------- */

.checkbox {
    input[type="checkbox"] {
        &:checked,
        &:not(:checked) {
            + span {
                &:before {
                    border-color: @input-border-color;
                    border-radius: @input-border-radius;
                    box-shadow: none;
                }
            }
        }

        &:checked + span::before {
            background: @brand-primary;
            box-shadow: none;
        }
    }
}

/* UI SLIDER
---------------------------------------------------------- */

.ui {
    &-slider {
        background: @white;

        &-range {
            background: @brand-primary;
        }

        &-handle {
            background: @white !important;
        }
    }
}

.device-content {
    hr {
        display: none;
    }

    .variants {
        .form-group {
            padding: 0 20px;

            label {
                font-weight: normal;
            }
        }
    }

    .price {
        span {
            display: block;
            float: right;
            font-weight: bold;
        }
    }
}

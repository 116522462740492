@import '_mixins';

body {
  .pagination-list {
    margin-top: 0px;
  }

  input[type=date]::-webkit-inner-spin-button,
  input[type=date]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@import 'Helper/_button';
@import 'Helper/_sidebar';
@import 'Helper/_form';
@import 'Helper/_grid';
@import 'Helper/_list';
@import 'Helper/_carousel';
@import 'Helper/_panel';
@import 'Helper/_product-gallery';
@import 'Helper/_flap';
@import 'Helper/_border';
@import 'Helper/_spacing';
@import 'Helper/_text';
@import 'Helper/_images';

@import 'Widget/_ck_editor';
@import 'Widget/_contact';

@import 'Parts/_additional-informations.less';
@import 'Parts/_price';
@import 'Parts/_navigation';

@import 'Pages/Tariff/_overview';
@import 'Pages/Tariff/_selectTariff';
@import 'Pages/Tariff/_tariffTable';
//libs

/* SLICK SLIDER */
.slick-track, .tariff-slide {
  width: auto;
  display: flex;
  justify-content: center;
}

.slick-slide, .tariff-wrapper {
  flex: 1 1 50% !important;
}

.display-flex {
  display: flex;
  flex-wrap: wrap;
}

.display-flex > [class*='col-'] {
  display: flex;
  flex-direction: column;
}

.display-flex > [class*='col-'] {
  flex-grow: 1;
}

.popover {
    border: none;
    border-radius: 0;
    padding: 0;

    &-title {
        border-radius: 0;
        color: @white;
        background-color: @brand-primary;
    }
}

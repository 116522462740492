/*  LOGIN HEADER
---------------------------------------------------------- */

#login-header {
    height: 392px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    h1,
    p {
        color: white;
    }

    h1{
        font-size: 38px;
    }

    p {
        font-size: 24px;
    }
}

/*  LOGIN PAGE
---------------------------------------------------------- */

.login-page {
    .text-primary {
        color: @brand-primary-light;
        font-size: 20px;
        margin-bottom: 10px;
    }

    .well {
        background-color: #ebecee;
    }

    .btn {
        padding: 8px;
    }

    .form {
        &-control {
            border-color: @gray-light;
        }
    }

    .contact-footer {
        padding: 20px 0 10px;
        font-size: @font-size-small;
        background-color: #ebecee;

        .text-primary {
            font-size: @font-size-small;
            color: @text-color;
        }

        .col-md-2,
        .col-md-3 {
            margin-top: 10px;
        }
    }
}

/*  LOGIN FOOTER
---------------------------------------------------------- */

#login-footer {
    a {
        color: @text-color;
    }
}

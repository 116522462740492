.tariff-overview {
    .slick {
        &-arrow {
            position: absolute;
            top: 50%;
            font-size: @font-size-large * 3;
            margin-top: -(@font-size-large * 3);
            color: @gray-light;
            background: transparent;
            border: 0;

            &.slick-prev {
                left: -50px;
            }

            &.slick-next {
                right: -50px;
            }
        }

        &-dots {
            padding: 0;
            margin: 0;
            text-align: center;

            li {
                display: inline-block;
                margin-left: 5px;
                margin-right: 5px;
                list-style-type: none;

                button {
                    overflow: hidden;
                    width: 17px;
                    height: 17px;
                    text-indent: -1000px;
                    background-color: @gray-lighter;
                    border: 0;
                    border-radius: 50%;
                }

                &.slick-active {
                    button {
                        background-color: @brand-primary;
                    }
                }
            }
        }
    }
}
.form-control {
    &:focus {
        border-color: @brand-primary;
        outline: 0;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px fade(@brand-primary, 60%);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px fade(@brand-primary, 60%);
    }
}

.checkbox {

    label {
        padding-left: 0 !important;
    }

    /* remove standard-styles */
    input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: none;
        border-radius: 0;
        font-size: 1em;
        width: 100%;
        display: none;
    }

    /* graceful degradation for ie8 */
    input[type='checkbox'] {
        width: auto;
        float: left;
        margin-right: .75em;
        background: transparent;
        border: none;
    }

    input[type='checkbox']:checked,
    input[type='checkbox']:not(:checked) {
        background: transparent;
        position: relative;
        visibility: hidden;
        margin: 0;
        padding: 0;
        display: none;
    }

    input[type='checkbox'] + span {
        cursor: pointer;
        height: 18px;
        width: 18px;
        display: inline-block;
        margin-right: 6px;
        position: relative;
    }

    input[type='checkbox']:checked + span::before,
    input[type='checkbox']:not(:checked) + span::before {
        content: ' ';
        display: inline-block;
        width: 18px;
        height: 18px;
        position: relative;
        top: 3px;
        border: 1px solid darken(@gray-lighter, 5%);
        background: white;
        margin-right: 1em;
        border-radius: 4px;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3) inset;
    }

    input[type='checkbox']:checked + span::before {
        background: @green;
        box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.3) inset;
        border-radius: 4px;
    }

    input[type='checkbox']:checked + span::after {
        font-family: @font-awesome;
        font-weight: 900;
        content: "\f00c";
        color: @white;
        position: absolute;
        left: 3px;
        top: 4px;
        font-size: 12px;
    }
}

.flap {
    position: absolute;
    top: 128px;
    z-index: 20;

    &-left {
        left: 0;

        .flap-btn {
            right: -35px;

            &.collapsed {
                left: 0;
            }
        }
    }

    &-right {
        right: 0;

        .flap-btn {
            left: -35px;

            &.collapsed {
                right: 0;
            }
        }
    }

    &-btn {
        position: absolute;
        display: block;
        width: 35px;
        height: 54px;

        .button-size(0, 0, @font-size-large, @line-height-large, 0);

        border: 0;
        color: @body-bg;
        background: @brand-primary;
    }

    &-content {
        color: @body-bg;
        background: @brand-primary;
        width: 0;
        height: 0;

        &.collapsing {
            -webkit-transition: none;
            transition: none;
        }

        &.in {
            width: auto;
            height: auto;
        }

        .row {
            padding: 15px;
        }

        a {
            color: white;
            text-decoration: none;

            &:hover {
                color: white;
                text-decoration: underline;
            }
        }
    }
}

@media only screen and (min-width : 768px) {
    .flap {
        position: fixed;
        top: 40%;
    }
}

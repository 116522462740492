@import '_variables';
@import '_mixins';
@import '_icons';
@import '../../import/_theme';
@import 'font';
@import '~bootstrap3/less/bootstrap.less';
/*  LAYOUT
---------------------------------------------------------- */
@import 'layout/_button';
@import 'layout/_navigation';
@import 'layout/_header';
@import 'layout/_footer';
@import 'layout/_panel';
@import 'layout/_list';
@import 'layout/_form';
@import 'layout/_popover';
@import 'layout/_table';
@import 'layout/_well';
@import 'layout/_slider';
@import 'layout/_responsive';
/*  PAGES
---------------------------------------------------------- */
@import 'pages/Cart/_list';
@import 'pages/Default/_index';
@import 'pages/Device/_overview';
@import 'pages/Device/Helper/_device-content';
@import 'pages/FAQ/_index';
@import 'pages/Order/Checkout/_index';
@import 'pages/Order/Checkout/_progress';
@import 'pages/Order/Checkout/_steps';
@import 'pages/Order/Checkout/_success';
@import 'pages/Tariff/_filter';
@import 'pages/Tariff/_overview';
@import 'pages/Tariff/_preview';
@import 'pages/Tariff/_selectTariff';
@import 'pages/Tariff/_selectOptions';
@import 'pages/Tariff/_tariffTable';
@import 'pages/Tariff/_tariffInfo';
@import 'pages/Security/_login';
@import 'pages/Registration/_register';
/*  PAGES
---------------------------------------------------------- */
@import 'parts/_price';

&.font-red {
  color: @dark-red;
}

&.font-green {
  color: @light-green;
}

.cart {

  &.panel-default {
    .panel-heading {
      background-color: #f5f5f5;
    }
  }

  p {
    &.total-price {
      font-size: 1.26rem;
    }
  }

  .cart-item,
  .cart-sum {
  @include clearfix;
    .name {
      position: relative;
      display: block;
      padding: 0 0 15px 0;
      .price {
        position: relative;
        right: 0;
        text-align: right;
        float: right;
        small {
          display: block;
          padding-left: 1.5rem;
        }
      }
    }
  }
  .cart-sum {
    border-top: 1px solid #ddd;
    margin: 7px 0 15px;
    padding: 7px 0 0;
    font-weight: bold;
  }
  .cart-item-highlight {
    background: @brand-primary-light;
    color: #fff;
    margin: 10px -21px 5px -30px;
    padding: 10px 21px 10px 30px;
    position: relative;

    span.name {
      padding: 0;
    }
    &:before {
      content: "";
      position: absolute;
      top: -15px;
      left: 0;

      z-index: -1;

      display: block;
      border-top: 15px solid transparent;
      border-right: 15px solid @brand-primary-dark;
    }
  }
}

.option-panel {
  .panel-heading {
    background: none;
    padding: 20px 15px 20px 85px;
    position: relative;
    font-size: 20px;
    min-height: 70px;
    overflow: hidden;
    border-bottom: 1px solid #ddd;
    .accordion-toggle {
      &:before {
        content: "\f138";
        display: block;
        width: 70px;
        height: 70px;
        color: #fff;
        background: @brand-primary;
        text-align: center;
        font-size: 28px;
        position: absolute;
        top: 0;
        left: 0;
        padding: 15px 0 0;
      }
      &:after {
        font-family: @font-awesome;
        font-weight: 900;
        content: "\f102";
        padding: 0;
        float: right;
        color: @brand-primary;
      }
      &.collapsed {
        &:before {
          content: "\f138";
        }
        &:after {
          content: "\f103";
        }
      }
    }
  }

  .form-group {
    margin-top: 15px;
    margin-bottom: 0;
  }

  .radio,
  .checkbox {
    margin: 0 0 20px;

    @media (min-width: @screen-sm-min) {
      font-size: 20px;
    }

    input {
      top: -2px;
      left: 15px;

      @media (min-width: @screen-sm-min) {
        top: 9px;
        right: 0;
        left: auto;
      }
    }

    .price {
      display: block;
    }

    @media (min-width: @screen-sm-min) {
      i.fa {
        position: absolute;
        top: 9px;
        right: 110px;
      }

      .price {
        display: inline;
        position: absolute;
        right: 24px;
        top: 5px;
        font-weight: bold;
        text-align: right;

        small {
          display: block;
          font-weight: normal;
          font-size: 12px;
          line-height: 12px;
        }
      }

      label {
        padding-left: 0;
      }
    }
  }
  .checkbox .price {
    margin-left: 26px;

    @media (min-width: @screen-sm-min) {
      margin-left: 0;
    }
  }

  @media (min-width: @screen-sm-min) {
    .checkbox > label > span {
      position: absolute;
      top: 0;
      right: -10px;
    }
  }
}


.panel-footnote {
  font-size: 12px;
}

.price {
  .price-with-footnote {
    span {
      &.footnote {
        position: absolute;
        right: -8px;
      }
    }
  }
}

#accordion-order {
  .panel {
    border: none;
  }
}

.others {
  &.other-tariffs {
    .item {
      &.is--active {
        border: 1px solid @brand-primary-light;
      }
    }
  }
}

/* Homepage slider */
.homepage-slider {

  .carousel-inner {

    width: 100%;
    height: 500px;

    .item {
      height: 100%;
    }
  }

  .slider-image {
    height: 494px;
    display: flex;
    background-size: cover;
    background-position: center;
  }
}

/* tariffs button */
.btn-all-tariffs {
  margin-top: -15px;
}

.btn-squre-40 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

@media (max-width: 768px) {
  .upload-queue-button {
    margin-top: 1.5rem !important;
  }
}

/* fix pagination item z-index */
.pagination {
  &.pagination-list {
    li {
      &.active {
        span {
          z-index: 1 !important;
        }
      }
    }
  }
}

.startingcredit {
  font-size: 20px;
  font-weight: bold;
  color: white;
}

.startingcredit-wrapper {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 50px;
  width: fit-content;
  padding: 10px;
  text-align: left;
}

.option-panel {
    .bs-modal-remote{
        position: absolute;
        right:50px;
        top:20px;
    }

}

.order-step {

    /*  SUMMARY
    ---------------------------------------------------------- */

    &-summary {
        dd {
            margin-bottom: 10px;
        }
    }
}

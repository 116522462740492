@font-face {
  font-family: FrutigerLight;
  font-weight: 400;
  font-style: normal;
  src: url('@{font-dir}/frutiger-light.woff') format("woff")
}

@font-face {
  font-family: FrutigerBold;
  font-weight: 400;
  font-style: normal;
  src: url('@{font-dir}/frutiger-bold.woff') format("woff")
}

header {
    .brand-gradient-background();

    height: 158px;
    transition: height .1s ease-in-out;

    .logo-header {
        display: flex;
        height: 158px;
        align-items: center;
    }

}

.is-active-nav-dropdown {
    header {
        height: 548px;
    }
}

/*  HOMEPAGE HEADER
---------------------------------------------------------- */

.home-page {
    header {
        height: 405px;

        @media (min-width: @screen-sm-min) {
            height: 544px;
        }
    }

    .homepage-slider {
        display: none;

        @media (min-width: @screen-sm-min) {
            display: block;
        }
    }

    .homepage-slider .img-responsive {
        opacity: 1;
        transition: opacity .1s ease-in-out;
    }

    &.is-active-nav-dropdown {
        .homepage-slider .img-responsive {
            opacity: 0;
        }
    }
}

/*  PAGE HEADER
---------------------------------------------------------- */

.page-header {
    padding-top: 40px;
    border-bottom: none;
}

/*  PRODUCT
---------------------------------------------------------- */

.product {
    margin-bottom: 20px;

    .other-tariffs {
        overflow: hidden;
        margin: 0 0 20px -10px;

        .prev, .next {
            display: none;
        }

        .item,
        .prev,
        .next {
            position: relative;
            height: 64px;
            float: left;
            margin-left: 10px;
            font-weight: bold;
            text-align: center;

            i,
            span {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: block;
                width: 100%;
                color: @brand-primary;
            }
        }

        .item {
            width: 84px;
            font-size: @font-size-small;
            background-color: @white;
        }

        .prev,
        .next {
            width: 32px;
            font-size: @font-size-large;
            background-color: @gray-lighter;
            transition: background-color @transition-default-time @transition-default-animation;

            &:hover {
                background-color: darken(@gray-lighter, 10%);
            }
        }
    }

    .name {
        color: @brand-primary;
    }

    .price {
        font-size: @font-size-h2;
        font-weight: bold;

        small {
            font-size: @font-size-small;
        }
    }
}

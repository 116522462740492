.page-footer {
    background-color: @gray-lighter;

    h3.text-uppercase {
        font-weight: bold;
    }

    p {
        font-weight: bold;
        font-size: @font-size-h3;
        line-height: 1;

        &.opening-hours {
            width: 50%;
            padding-top: 1rem;
            margin: 0 auto;
        }

        small {
            font-weight: normal;
            font-size: (@font-size-h3 / 1.5);
        }
    }

    a {
        color: @text-color;
        font-weight: bold;
    }

    &-nav {
        font-size: @font-size-base;
        border-top: @border-default-width @border-default-style @border-default-color;
    }

    ul {
        &.list-inline.footer-nav {
            li {
                padding-left: 20px;
                padding-right: 20px;
            }
        }
    }
}

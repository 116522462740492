/* PANEL
---------------------------------------------------------- */
.panel {

    &.panel-dark {
        border: 1px solid @brand-primary-dark;
        &.panel-heading {
            background-color: #082745;
        }
    }

    &.tarif-preview {
        border: none !important;
    }

    &-default {

        border: none;

        &.option-panel {
            border: 1px solid #ddd;
        }

        > .panel {
            &-heading,
            &-body,
            &-footer {
                padding: 20px;
                background-color: transparent;
                border: none;
            }

            &-heading {
                color: @brand-primary;
                font-weight: bold;
                border-bottom: @border-default-width @border-default-style @body-bg;
            }

            &-footer {
                border-top: @border-default-width @border-default-style @body-bg;
            }

        }
    }

    &.cart {
        border: 1px solid #ddd;
    }

    &-primary {
        background-color: transparent;
        border-color: transparent;

        > .panel {
            &-heading {
                padding: 0 0 20px;
                color: @text-color;
                font-size: @font-size-h3;
                font-weight: bold;
                background-color: transparent;
                border: none;
            }

            &-body {
                padding: 0;
            }
        }
    }
}

/*  TARIFF PANEL
---------------------------------------------------------- */

.tariff-wrapper {
    .tariff {
        position: relative;
        color: @brand-primary-dark;
        border: none;
        box-shadow: none;
    }

    .badge.topseller {
        .topseller(@topseller-badge-color, @white, 10px, -10px);
    }

    .panel {
        &-heading {
            padding: 50px 20px;
            text-align: center;
            color: white;
            /*background-color: transparent;*/
            .brand-gradient-background();

            .tariff-name {
                font-size: 40px;
                font-weight: bold;
                justify-content: center;

                .brand {
                    display: none;
                }
            }
        }

        &-body {
            padding: 15px;

            .tariff-keyfacts {
                ul {

                    margin: 0;
                    padding: 0;

                    li {
                        list-style-type: none;
                        padding-top: .5rem;
                        padding-bottom: .5rem;
                        font-size: 16px;
                        padding-left: 2rem;

                        &::before {
                            font-family: "Font Awesome 5 Free";
                            font-weight: 900;
                            content: "\F00C";
                            padding-right: 1.5rem;
                            position: absolute;
                            left: 10px;
                            color: #0d71ac;
                        }
                    }
                }
            }

            .tariff-product-information-link {
                padding-top: 1rem;
            }

        }

        &-footer {
            border-top: none;
            padding: 10px 0 0;

            .price-lead {
                margin: 0;
                padding: 0 10px 10px;
                text-align: right;
                border-bottom: @border-default-width @border-default-style @body-bg;
            }

            .button-wrapper {
                padding: 0 20px;
            }
        }
    }
}

/*  CART PANEL
---------------------------------------------------------- */

.panel.cart {
    margin-top: 40px;

    .comparison-item {

        font-weight: bold;
        font-size: 13px;

        .is--right {
            display: block;
            text-align: right;
        }

    }

    .panel-body {
        .cart-advantage {
            border-top: none !important;
            padding-top: 0 !important;
        }
    }

    .cart {

        font-size: 13px;

        &-title {
            &:not(:first-child) {
                margin-top: 20px;
            }
        }

        &-sum {
            margin: 10px 0 15px;
            padding-top: 10px;
            font-weight: bold;
            border-top: 1px solid #ddd !important;
        }
    }
}

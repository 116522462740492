/*  WRAPPER
---------------------------------------------------------- */

#select-tariff-wrapper {
    hr {
        display: none;
    }
}

/*  DEVICE HEADER
---------------------------------------------------------- */

.deviceHeader {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 40px;

    .name {
        margin-bottom: 28px;
        font-size: (@font-size-large * 1.2);
        font-weight: bold;
    }

    .col-md-2 {
        /*background-color: @gray-lighter;*/

        .bs-modal {
            display: block;
            padding: 20px 0;
            text-align: center;
        }
    }

    .col-md-10 {
        padding: 20px;
        background-color: white;
    }

    .btn {
        margin-top: 12px;
    }
}

header {
    position: relative;
}

.vendor-logo {
    float: right;
    margin-left: 30px;

    @media (max-width: @screen-xs-max) {
        display: none;
    }
}
.user-navigation {
    #user-nav {
        padding-right: 0;

        @media (min-width: @screen-md-min) {
            margin-right: 150px;
        }

        a {
            color: @text-color;
        }
    }

    .navbar {
        &-toggle {
            padding: 20px 20px 15px 0;
            color: @brand-primary;
        }

        &-brand {
            height: inherit;
            font-size: 20px;
            padding: 15px 0 15px 15px;

            a {
                color: @navbar-default-brand-color;
                &:hover {
                    text-decoration: none;
                }
            }

            > span {
                display: none;

                @media (min-width: @screen-sm-min) {
                    display: inline-block;
                }
            }

            > img {
                display: inline-block;
            }
        }

        &-nav {
            @media (min-width: @screen-sm-min) {
                margin-top: 14px;
            }
        }
    }

    .vendor-logo {
        display: none;
        float: right;
        margin-left: 30px;

        @media (min-width: @screen-md-min) {
            display: inline-block;
            float: right;
            margin-top: -70px;
            margin-left: 30px;
        }
    }
}

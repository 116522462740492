.tariffOverview {
    color: @text-color;
    font-size: 18px;

    thead {
        tr {
            font-size: (@font-size-base * 1.8);
        }
    }

    tbody {
        td {
            &.device {
                /*&.preferred {
                    color: white;
                    background: @brand-primary;
                }*/

                span {
                    display: block;
                    font-size: @font-size-small;

                    &.price-monthly {
                        color: @brand-primary;
                        font-weight: bold;
                        font-size: 22px;
                    }
                }
            }
        }


    }

    .fa-check, .fa-times {
        color: @brand-primary-light;
        font-size: 20px;
    }

}

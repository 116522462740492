.checkout-progress {
    width: 100%;
    margin: 0 0 20px;
    padding: 0;

    @media (min-width: @screen-md-min) {
        display: -ms-flexbox;
        display: flex;
    }

    .list-group-item {
        position: relative;
        padding: 19px 0;
        text-align: center;
        background: @gray-lighter;
        border: none;
        border-radius: 0;

        &.active {
            background-color: @brand-primary;

            a {
                color: white;
            }
        }

        @media (min-width: @screen-md-min) {
            -ms-flex: 1 1 auto;
            flex: 1 1 auto;

            &:after,
            &:before {
                position: absolute;
                top: 0;
                content: "";
                display: block;
                border-top: 30px solid transparent;
                border-bottom: 30px solid transparent;
            }

            &:after {
                right: -30px;
                border-left: 30px solid @gray-lighter;
                z-index: 11;
            }

            &:before {
                top: -5px;
                right: -35px;
                border-left: solid @body-bg;
                border-width: 35px;
                z-index: 10;
            }

            &.active:after {
                border-left-color: @brand-primary;
            }

            &:last-child {
                &:before,
                &:after {
                    content: unset;
                }
            }
        }
    }
}